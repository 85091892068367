import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import parse from 'date-fns/parse';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';

import 'react-datepicker/dist/react-datepicker.css';

const testPage = () => {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [pax, setPax] = useState(0);
  const [value, setValue] = useState(0);

  const diffDays = () => {
    const diff = differenceInCalendarDays(parse(end), parse(start));
    return;
  };

  const handleChangeStart = date => {
    setStart(date);
  };

  const handleChangeEnd = date => {
    setEnd(date);
  };

  useEffect(
    () => {
      diffDays();
    },
    [handleChangeEnd, handleChangeStart]
  );

  return (
    <form>
      <DatePicker
        selected={start}
        selectsStart
        startDate={start}
        endDate={end}
        dateFormat="dd/MM/yyyy"
        onChange={handleChangeStart}
        placeholderText="Arrive"
        minDate={new Date()}
      />

      <DatePicker
        selected={end}
        selectsEnd
        dateFormat="dd/MM/yyyy"
        startDate={start}
        endDate={end}
        onChange={handleChangeEnd}
        placeholderText="Depart"
        minDate={new Date()}
      />
    </form>
  );
};

export default testPage;
